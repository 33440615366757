<template>
  <div id="app">
  <!--
    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About</router-link></li>
      </ul>
    </nav>
	-->
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped>
nav ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
}

nav li {
  padding: 10px;
}

main {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
}
</style>